// header 스크롤
window.onscroll = function(){

    var toolbar = document.body.querySelector(".toolbar");
    var mobile_toolbar = document.body.querySelector(".mobile_toolbar");

    var windowWith = window.innerWidth;
    var windowTop = window.scrollY;

    if(windowWith > 1300){

        var toolbarHeight = toolbar.offsetHeight;
        mobile_toolbar.classList.remove("blur");

        if(windowTop >= toolbarHeight){
            toolbar.classList.add("blur");
        }else{
            toolbar.classList.remove("blur");
        }

    }else{

        var mobile_toolbarHeight = 0;
        if(location.href.indexOf("/main") > -1) mobile_toolbarHeight = 60;
        toolbar.classList.remove("blur");

        if(windowTop >= mobile_toolbarHeight){
            mobile_toolbar.classList.add("blur");
        }else{
            mobile_toolbar.classList.remove("blur");
        }
    }
};


// 별 생성
function init_background(){

    //estrelas
    var style = ["style1", "style2", "style3", "style4"];
    var tam = ["tam1", "tam1", "tam1", "tam2", "tam3"];
    var opacity = ["opacity1", "opacity1", "opacity1", "opacity2", "opacity2", "opacity3"];

    function getRandomArbitrary(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    var estrela = "";
    var qtdeEstrelas = 150;
    var noite = document.querySelector(".constelacao");
    var widthWindow = window.innerWidth;
    var heightWindow = window.innerHeight;

    for (var i = 0; i < qtdeEstrelas; i++) {
        estrela += "<span class='estrela " + style[getRandomArbitrary(0, 4)] + " " + opacity[getRandomArbitrary(0, 6)] + " "
        + tam[getRandomArbitrary(0, 5)] + "' style='animation-delay: ." +getRandomArbitrary(0, 9)+ "s; left: "
        + getRandomArbitrary(0, widthWindow) + "px; top: " + getRandomArbitrary(0, heightWindow) + "px;' ></span>";
    }

    noite.innerHTML = estrela;
    
    //meteoros
    var numeroAleatorio = 5000;

    let timer1 = setTimeout(function(){
        carregarMeteoro();
    }, numeroAleatorio);

    function carregarMeteoro(){

        clearTimeout(timer1);
        const currentUrl = window.location.href;

        let timer2 = setTimeout(carregarMeteoro, numeroAleatorio);

        numeroAleatorio = getRandomArbitrary(5000, 10000);
        var meteoro = "<div class='meteoro "+ style[getRandomArbitrary(0, 4)] +"'></div>";
        
        if(currentUrl.indexOf('/main') > 0){
            document.getElementsByClassName('chuvaMeteoro')[0].innerHTML = meteoro;
            setTimeout(function(){
                if(document.getElementsByClassName('chuvaMeteoro')[0]){
                    document.getElementsByClassName('chuvaMeteoro')[0].innerHTML = "";
                }
            }, 1000);
        }else{
            clearTimeout(timer2);
        }

    }
}


// article 영역 fadeIn 효과
let observer = new IntersectionObserver((e)=>{
    e.forEach((box)=>{
        if(box.isIntersecting){
            box.target.style.animation = 'fadeInUp 1.5s';
            box.target.style.opacity = 1;
        }
    })
})


// article 영역 선택
function init_observer(){

    const currentUrl = window.location.href;

    if(currentUrl.indexOf('/main') > 0){
        let article = document.querySelectorAll('article');

        observer.observe(article[0]);
        observer.observe(article[1]);
        observer.observe(article[2]);
        observer.observe(article[3]);
        observer.observe(article[4]);
        // observer.observe(article[5]);
    }
}
/*
function init_scroll(){

    // const elm = document.querySelectorAll('section');
    const elm = document.querySelectorAll('article');
    const elmCount = elm.length;
    elm.forEach(function(item, index){
        item.addEventListener('mousewheel', function(event){
        event.preventDefault();
        let delta = 0;

        if (!event) event = window.event;
        if (event.wheelDelta) {
            delta = event.wheelDelta / 120;
            if (window.opera) delta = -delta;
        } 
        else if (event.detail)
            delta = -event.detail / 3;

        let moveTop = window.scrollY;
        let elmSelector = elm[index];

        // wheel down : move to next section
        if (delta < 0){
            if (elmSelector !== elmCount-1){
            try{
                moveTop = window.pageYOffset + elmSelector.nextElementSibling.getBoundingClientRect().top;

                if(elm[0]) moveTop = moveTop - 120;
                // moveTop = moveTop - 120;

            }catch(e){}
            }
        }
        // wheel up : move to previous section
        else{
            if (elmSelector !== 0){
            try{
                moveTop = window.pageYOffset + elmSelector.previousElementSibling.getBoundingClientRect().top;
            }catch(e){}
            }
        }

        const body = document.querySelector('html');
        window.scrollTo({top:moveTop, left:0, behavior:'smooth'});
        });
    });

}
*/